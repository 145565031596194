div.container-result-skeleton {
    padding: 1.5rem;
}

div.container-result-skeleton .relative {
    position: relative;
}

div.container-result-skeleton .text {
    background-color: #EEEEEE;
    border-radius: .3rem;
    color: transparent;
}

div.container-result-skeleton .button {
    background-color: #EEEEEE;
    border-radius: .3rem;
    color: #EEEEEE;
    padding: .5rem 1rem;
}

div.container-result-skeleton .board {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5rem;
    justify-content: center;
    margin-top: 5rem;
}

@media screen and (width >= 576px) {
    div.container-result-skeleton .board {
        justify-content: flex-start;
    }
}

div.container-result-skeleton .board .time {
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;
    gap: .3rem;
}

@media screen and (width >= 576px) {
    div.container-result-skeleton .board .time {
        flex: 0 1 auto;
    }
}

div.container-result-skeleton .board .time .result {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

div.container-result-skeleton .board .time .result .pizza {
    border: 15px solid #EEEEEE;
    border-radius: 50%;
    color: #EEEEEE;
    height: 80px;
    width: 80px;
}

div.container-result-skeleton .board .time hr {
    border-color: #EEEEEE;
    border-width: 1px;
    opacity: 1;
}

div.container-result-skeleton .board .time .timer,
div.container-result-skeleton .board .time .start,
div.container-result-skeleton .board .time .finish {
    display: flex;
    font-size: .8rem;
    justify-content: space-between;
    gap: 2rem;
}

div.container-result-skeleton .board .amount {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

div.container-result-skeleton .board .amount .count {
    align-items: flex-start;
    border: 3px solid #EEEEEE;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-around;
    padding: 1rem 1.5rem;
}

div.container-result-skeleton .board .amount .count .pipe {
    background-color: #EEEEEE;
    height: 100%;
    width: 2px;
}

div.container-result-skeleton .board .amount .count .correct,
div.container-result-skeleton .board .amount .count .incorrect,
div.container-result-skeleton .board .amount .count .blank {
    align-items: center;
    display: flex;
    flex-direction: column;
}

div.container-result-skeleton .board .statistic {
    border: 3px solid #E5E5E5;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem 2rem;
    width: 100%;
}

@media screen and (width >= 992px) {
    div.container-result-skeleton .board .statistic {
        max-width: 400px;
    }
}