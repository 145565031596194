.signup-logo-container {
    display: flex;
    justify-content: center;
    padding: 5rem 3rem;
}

.form-signup input {
    background-color: #F6F6FA;
}

.form-signup input::placeholder {
    color: #B8B8B8;
}

.form-signup-show-pass {
    border-top-right-radius: .375rem !important;
    border-bottom-right-radius: .375rem !important;
    cursor: pointer;
}

.form-signup-show-pass svg {
    height: 20px;
    width: 20px;
}

.form-signup-info-password {
    font-size: 0.7rem;
    text-align: justify;
    user-select: none;
}

.acceptTermsConditions {
    align-items: center;
    display: flex;
    gap: 0.5rem;
    margin: 1.5rem 0;
}

.acceptTermsConditions input {
    height: 1.3rem;
    margin: 0;
    width: 1.3rem;
}

.acceptTermsConditions label {
    column-gap: .3rem;
    display: flex;
    flex-wrap: wrap;
    font-size: 0.8rem;
    user-select: none;
}

.form-signup-button {
    background-color: #0247BC;
    font-weight: bold;
    width: 100%;
}

.form-signup-yesaccount {
    align-items: center;
    font-size: 0.8rem;
    display: flex;
    flex-wrap: wrap;
    gap: .3rem;
    margin: 1.5rem 0;
    user-select: none;
}

.form-signup-signup {
    color: #0A58CA;
    text-decoration: none;
}

.form-signup-signup:hover {
    color: #0A58CA;
    text-decoration: underline;
}