div.simulated-container  {
    display: flex;
    flex-direction: column;
}

@media screen and (width >= 768px) {
    div.simulated-container {
    }
}

@media screen and (width >= 992px) {
    div.simulated-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        grid-template-areas: "question alternatives";
    }
}

div.question-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 1rem;
}

@media screen and (width >= 768px) {
    div.question-container {
        padding: 1.5rem;
    }
}

@media screen and (width >= 992px) {
    div.question-container {
        grid-area: question;
    }
}

div.question-container .fade-effect {
    animation: fade-in 2s forwards;
}

@keyframes fade-in {
    0% {
        transform: translateX(.3rem);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

div.question-container div.question {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 4rem 0;
}

@media screen and (width >= 768px) {
    div.question-container div.question {
        padding: 4rem 2rem;
    }
}

@media screen and (width >= 992px) {
    div.question-container div.question {
        padding: 6rem 0 0 0;
    }
}

@media screen and (width >= 1400px) {
    div.question-container div.question {
        padding: 8rem 5rem 0;
    }
}

div.question-container div.question div.number {
    color: #666666;
    font-size: 1.1rem;
    user-select: none;
}

div.question-container div.question div.title {
    font-size: 1.5rem;
    font-weight: 500;
    user-select: none;
}

div.question-container div.question div.image {
    align-items: center;
    display: flex;
    justify-content: flex-start;
}

div.question-container div.question div.image img {
    border: none;
    height: auto;
    width: 120px;
}

div.alternatives-container {
    background-color: #F9F9F9;
    box-shadow: inset 0px 1px 2px 0px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

@media screen and (width >= 768px) {
    div.alternatives-container {
        padding: 2rem;
    }
}

@media screen and (width >= 992px) {
    div.alternatives-container {
        border-top-left-radius: 5px;
        box-shadow: inset 1px 0px 2px 0px rgba(0,0,0,0.1);
        grid-area: alternatives;
        padding: 1rem;
    }
}

@media screen and (width >= 1200px) {
    div.alternatives-container {
        padding: 2rem;
    }
}

div.alternatives-container div.board {
    background-color: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
    display: flex;
    flex: 1;
    flex-direction: column;
}

@media screen and (width >= 992px) {
    div.alternatives-container div.board {
        max-width: 500px;
    }
}

div.alternatives-container div.content {
    align-items: center;
    border-bottom: 1px solid #E5E5E5;
    display: flex;
    gap: 1rem;
    padding: 1rem 2rem;
    user-select: none;
}

div.alternatives-container div.content svg {
    height: 40px;
    width: 40px;
}

div.alternatives-container div.content span {
    font-size: 1rem;
    display: flex;
    flex-direction: column;
}

div.alternatives-container div.content span b {
    font-size: 0.9rem;
    font-weight: bold;
}

div.alternatives-container div.options {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0 2rem;
}

div.alternatives-container div.options input[type='radio']:checked + label span {
    font-weight: 500;
}

div.alternatives-container div.options input[type='radio']:checked + label i {
    background-color: #0B6FFE;
    color: #FFFFFF;
}

div.alternatives-container div.options label {
    align-items: center;
    border-bottom: 1px solid #E5E5E5;
    cursor: pointer;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    padding: 1rem 0;
    flex-wrap: nowrap;
    user-select: none;
}

div.alternatives-container div.options div:last-of-type label {
    border-bottom: none;
}

div.alternatives-container div.options label span {
    display: flex;
    flex: 1;
}

div.alternatives-container div.options label i {
    align-items: center;
    border: 2px solid #E5E5E5;
    border-radius: 50%;
    color: #000000;
    display: flex;
    font-style: normal;
    font-weight: 500;
    height: 40px;
    justify-content: center;
    width: 40px;
}

div.alternatives-container div.navegation {
    border-top: 1px solid #E5E5E5;
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
}

div.alternatives-container div.navegation button.button-navigation,
div.alternatives-container div.navegation button.button-navigation:disabled {
    background-color: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: .3rem;
    height: 3rem;
    width: 4rem;
}

div.alternatives-container div.navegation button.button-navigation:not([disabled]):active {
    background-color: #F9F9F9;
}

div.alternatives-container div.navegation button.button-navigation:not([disabled]):hover {
    background-color: #E1F3FF;
    border-color: #E1F3FF;
}

div.alternatives-container div.navegation button.button-navigation:disabled svg {
    fill: #BBBBBB;
}

div.alternatives-container div.navegation button.button-navigation svg {
    fill: #404040;
    height: 30px;
    width: 30px;
}

div.alternatives-container div.navegation button.button-finish {
    background-color: #DC3645;
    border: none;
    border-radius: .3rem;
    color: #FFFFFF;
    box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.1);
    font-weight: 500;
    height: 3rem;
    width: 8rem;
}

div.alternatives-container div.navegation button.button-finish:hover {
    background-color: #BB2D3B;
}

div.alternatives-container div.navegation button.button-finish:active {
    background-color: #B02A37;
}

div.spinner-container {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 40px;
    width: 40px;
}

div.spinner-border {
    height: 25px;
    width: 25px;
}