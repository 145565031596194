div.container-plans {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 3rem;
}

@media screen and (width >= 576px) {
    div.container-plans {
        flex-direction: row;
        flex-wrap: wrap;
    }
}    