div.instructions-redefine-pass {
    display: flex;
    flex-direction: column;
    font-size: .7rem;
    margin-top: 1rem;
}

div.instructions-redefine-pass-title {
    border-bottom: 1px solid #DEE2E6;
    padding: .5rem 0;
}

div.instructions-redefine-pass-title span {
    color: #000000;
}

div.instructions-redefine-pass-content {
    color: #727272;
    display: flex;
    flex-direction: column;
    gap: .4rem;
    margin-top: .5rem;
}

div.instructions-redefine-pass-row {
    display: flex;
    flex-direction: row;
    gap: .5rem;
}

div.instructions-redefine-pass-number {
    align-items: center;
    background-color: #D2D2D2;
    border-radius: 50%;
    color: #FFFFFF;
    display: flex;
    font-size: .6rem;
    height: 1rem;
    justify-content: center;
    width: 1rem;
}

div.instructions-redefine-pass-text {
    color: #727272;
}