div.payment-information {
    align-items: center;
    border-bottom: 1px solid #DEE2E6;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin: 0 0 1.5rem 0;
    padding-bottom: 1rem;
    user-select: none;
}

div.payment-information div.icon {
    
}

div.payment-information div.icon svg {
    height: 40px;
    width: 40px;
}

div.payment-information div.text {
    align-self: flex-end;
    display: flex;
    flex: 1;
    flex-direction: column;
    line-height: 1.1rem;
}

div.payment-information div.text span {
    font-size: 1.1rem;
    font-weight: 500;
}

div.payment-information div.text i {
    color: #727272;
    font-size: .8rem;
    font-style: normal;
}

div.payment-information div.radio input[type='radio'] {
    height: 1.2rem;
    width: 1.2rem;
}