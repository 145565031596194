div.skeleton-qrcode-modal-content {
    position: relative;
}

div.skeleton-qrcode-modal-content span {
    background-color: #EEEEEE;
    border-radius: .375rem;
    color: #EEEEEE;
}

div.skeleton-qrcode-modal-content div.img {
    background-color: #EEEEEE;
    border: none;
    border-radius: .3rem;
    display: block;
    height: 200px;
    width: 200px;
}

div.skeleton-qrcode-modal-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

div.skeleton-qrcode-modal-container div.listGroup {
    display: flex;
    flex-direction: column;
    width: 100%;
}

div.skeleton-qrcode-modal-container div.listRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: .5rem 0;
}

div.skeleton-qrcode-modal-container div.listRow + div.listRow {
    border-top: 1px solid #DEE2E6;
}

div.skeleton-qrcode-modal-container div.listCol {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

div.qrcode-container div.listCol:first-of-type {
    align-items: flex-start;
}

div.qrcode-container div.listCol:last-of-type {
    align-items: flex-end;
}

div.skeleton-qrcode-modal-container div.input {
    background-color: #EEEEEE;
    border: 1px solid #EEEEEE;
    border-radius: .375rem;
    color: #EEEEEE;
    display: flex;
    padding: .375rem .75rem;
    width: 100%;
}

div.skeleton-qrcode-modal-container div.button-container {
    display: flex;
}

div.skeleton-qrcode-modal-container div.button-container div.button {
    background-color: #EEEEEE;
    border: 1px solid #EEEEEE;
    border-radius: .375rem;
    color: #EEEEEE;
    display: flex;
    padding: .375rem .75rem;
}

div.skeleton-qrcode-modal-instructions {
    display: flex;
    flex-direction: column;
    font-size: .7rem;
    margin-top: 1rem;
}

div.skeleton-qrcode-modal-instructions-title {
    border-bottom: 1px solid #DEE2E6;
    padding: .5rem 0;
}

div.skeleton-qrcode-modal-instructions-title span {
    background-color: #EEEEEE;
    border-radius: .375rem;
    color: #EEEEEE;
}

div.skeleton-qrcode-modal-instructions-content {
    display: flex;
    flex-direction: column;
    gap: .4rem;
    margin-top: .5rem;
}

div.skeleton-qrcode-modal-instructions-row {
    display: flex;
    flex-direction: row;
    gap: .5rem;
}

div.skeleton-qrcode-modal-instructions-number {
    align-items: center;
    background-color: #EEEEEE;
    border-radius: 50%;
    color: #EEEEEE;
    display: flex;
    font-size: .6rem;
    height: 1rem;
    justify-content: center;
    width: 1rem;
}

div.skeleton-qrcode-modal-instructions-text {
    background-color: #EEEEEE;
    border-radius: .375rem;
    color: #EEEEEE;
}

div.skeleton-qrcode-modal-content div.shimmer-effect {
    border-radius: .5rem;
}