div.container-email-confirm-skeleton {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 0 auto;
    max-width: 1000px;
    padding: 20px;
    position: relative;
}

@media screen and (width >= 768px) {
    div.container-email-confirm-skeleton {
        flex-direction: row;
        padding: 100px 20px;
    }
}

div.container-email-confirm-skeleton div.left {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
}

div.container-email-confirm-skeleton div.left div.image {
    background-color: #EEEEEE;
    border-radius: .3rem;
    display: block;
    height: 200px;
    width: 200px;
}

@media screen and (width >= 768px) {
    div.container-email-confirm-skeleton div.left div.image {
        height: 350px;
        width: 350px;
    }
}

div.container-email-confirm-skeleton div.right {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 1rem;
}

div.container-email-confirm-skeleton div.right div.logo {
    background-color: #EEEEEE;
    border-radius: .3rem;
    height: 80px;
    margin: 2.5rem 1.5rem;
    width: 220px;
}


div.container-email-confirm-skeleton div.right h2,
div.container-email-confirm-skeleton div.right span {
    background-color: #EEEEEE;
    border-radius: .3rem;
    color: transparent;
}

div.container-email-confirm-skeleton div.right p {
    text-align: center;
}

div.container-email-confirm-skeleton div.button {
    background-color: #EEEEEE;
    border-radius: .3rem;
    color: transparent;
    max-width: 200px;
    padding: .5rem 3rem;
    width: 100%;
}