div.container-email-confirm {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 0 auto;
    max-width: 1000px;
    padding: 20px;
}

@media screen and (width >= 768px) {
    div.container-email-confirm {
        flex-direction: row;
        padding: 100px 20px;
    }
}

div.container-email-confirm div.left {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
}

div.container-email-confirm div.right {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 1rem;
}

div.container-email-confirm div.right h2,
div.container-email-confirm div.right p {
    text-align: center;
}

div.container-email-confirm a.button {
    background-color: #0247BC;
    font-weight: bold;
    max-width: 200px;
    width: 100%;
}

div.container-email-confirm img.email-marketing {
    border: none;
    height: auto;
    max-width: 200px;
    width: 100%;
}

@media screen and (width >= 768px) {
    div.container-email-confirm img.email-marketing {
        max-width: 350px;
    }
}