div.rating-container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
    user-select: none;
}

div.rating-legend {
    display: flex;
    font-size: 1rem;
}

button.rating-button-comment {
    font-size: 0.8rem;
    padding: .1rem .3rem;
}