div.container-simulated-skeleton  {
    display: flex;
    flex-direction: column;
    position: relative;
}

@media screen and (width >= 992px) {
    div.container-simulated-skeleton {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        grid-template-areas: "question alternatives";
    }
}

div.container-simulated-skeleton div.question-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 1rem;
}

@media screen and (width >= 768px) {
    div.container-simulated-skeleton div.question-container {
        padding: 1.5rem;
    }
}

@media screen and (width >= 992px) {
    div.container-simulated-skeleton div.question-container {
        grid-area: question;
    }
}

div.container-simulated-skeleton div.question-container div.title {
    display: block;
}

div.container-simulated-skeleton div.question-container div.title span {
    background-color: #EEEEEE;
    border-radius: .3rem;
    color: #EEEEEE;
    font-size: 2rem;
    display: inline-block;
}

div.container-simulated-skeleton div.question-container div.question {
    display: block;
    padding: 4rem 0;
}

@media screen and (width >= 768px) {
    div.container-simulated-skeleton div.question-container div.question {
        padding: 4rem 2rem;
    }
}

@media screen and (width >= 992px) {
    div.container-simulated-skeleton div.question-container div.question {
        padding: 6rem 0 0 0;
    }
}

@media screen and (width >= 1200px) {
    div.container-simulated-skeleton div.question-container div.question {
        padding: 8rem 5rem 0;
    }
}

div.container-simulated-skeleton div.question-container div.question div.number {
    background-color: #EEEEEE;
    border-radius: .3rem;
    color: #EEEEEE;
    display: inline-block;
}

div.container-simulated-skeleton div.question-container div.question div.title {
    background-color: #EEEEEE;
    border-radius: .3rem;
    color: #EEEEEE;
    display: inline-block;
    margin-top: 1rem;
}

div.container-simulated-skeleton div.alternatives-container {
    background-color: #F9F9F9;
    box-shadow: inset 0px 1px 2px 0px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    padding: 3rem;
}

@media screen and (width >= 768px) {
    div.container-simulated-skeleton div.alternatives-container {
        padding: 2rem;
    }
}

@media screen and (width >= 992px) {
    div.container-simulated-skeleton div.alternatives-container {
        border-top-left-radius: 5px;
        box-shadow: inset 1px 0px 2px 0px rgba(0,0,0,0.1);
        grid-area: alternatives;
        padding: 1rem;
    }
}

@media screen and (width >= 1200px) {
    div.container-simulated-skeleton div.alternatives-container {
        padding: 2rem;
    }
}

div.container-simulated-skeleton div.alternatives-container div.board {
    background-color: #FFFFFF;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
    display: flex;
    flex: 1;
    flex-direction: column;
}

@media screen and (width >= 992px) {
    div.container-simulated-skeleton div.alternatives-container div.board {
        max-width: 500px;
    }
}

div.container-simulated-skeleton div.alternatives-container div.content {
    align-items: center;
    border-bottom: 1px solid #E5E5E5;
    display: flex;
    gap: 1rem;
    padding: 1rem 2rem;
    user-select: none;
}

div.container-simulated-skeleton div.alternatives-container div.content svg {
    height: 40px;
    width: 40px;
}

div.container-simulated-skeleton div.alternatives-container div.content span {
    background-color: #EEEEEE;
    border-radius: .3rem;
    color: #EEEEEE;
    display: inline-block;
}

div.container-simulated-skeleton div.alternatives-container div.options {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0 2rem;
}

div.container-simulated-skeleton div.alternatives-container div.options div.alternative {
    align-items: center;
    border-bottom: 1px solid #E5E5E5;
    cursor: pointer;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    padding: 1rem 0;
    flex-wrap: nowrap;
    user-select: none;
}

div.container-simulated-skeleton div.alternatives-container div.options div.alternative:last-of-type {
    border-bottom: none;
}

div.container-simulated-skeleton div.alternatives-container div.options div span {
    background-color: #EEEEEE;
    border-radius: .3rem;
    color: #EEEEEE;
    display: inline-block;
}

div.container-simulated-skeleton div.alternatives-container div.options div i {
    align-items: center;
    background-color: #E5E5E5;
    border: 2px solid #E5E5E5;
    border-radius: 50%;
    color: #E5E5E5;
    display: flex;
    font-style: normal;
    font-weight: 500;
    height: 40px;
    justify-content: center;
    width: 40px;
}

div.container-simulated-skeleton div.alternatives-container div.navegation {
    border-top: 1px solid #E5E5E5;
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
}

div.container-simulated-skeleton div.alternatives-container div.navegation div.button {
    background-color: #EEEEEE;
    border-radius: .3rem;
    height: 3rem;
    width: 4rem;
}