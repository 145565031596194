.container-form-user {
    margin: 3rem 0 0 0;
    padding: 0;
    max-width: 400px;
}

.form-user-button {
    margin: 1rem 0 0 0;
    width: 100%;
}

.container-email-user {
    margin: 3rem 0 0 0;
    max-width: 400px;
}

.container-email-user-confirmed,
.container-email-user-notconfirmed {
    align-items: center;
    display: flex;
    gap: 1rem;
    width: 100%;
}

.container-email-user-confirmed-icon svg {
    fill: #10B70C;
    height: 30px;
    width: 30px;
}

.container-email-user button {
    background-color: #CFCFCF;
    color: #000000;
    margin: 1rem 0 0 0;
    width: 100%;
}

.container-email-user button:hover {
    background-color: #B8B8B8;
    color: #000000;
}

.container-conta-user {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

.container-conta-user button {
    max-width: 400px;
    width: 100%;
}