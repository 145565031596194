.login-logo-container {
    display: flex;
    justify-content: center;
    padding: 5rem 3rem;
}

.form-login input {
    background-color: #F6F6FA;
}

.form-login input::placeholder {
    color: #B8B8B8;
}

.form-login-show-pass {
    border-top-right-radius: .375rem !important;
    border-bottom-right-radius: .375rem !important;
    cursor: pointer;
}

.form-login-show-pass svg {
    height: 20px;
    width: 20px;
}

.form-login-button {
    background-color: #0247BC;
    font-weight: bold;
    width: 100%;
}

.form-login-forgot-password {
    align-items: center;
    color: #585C5E;
    font-size: 0.8rem;
    display: flex;
    margin: 1.5rem 0;
    text-decoration: none;
    user-select: none;
}

.form-login-forgot-password:hover {
    text-decoration: underline;
}

.form-login-noaccount {
    align-items: center;
    font-size: 0.8rem;
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
    margin: 1.5rem 0;
    user-select: none;
}

.form-login-signup {
    color: #0A58CA;
    text-decoration: none;
}

.form-login-signup:hover {
    color: #0A58CA;
    text-decoration: underline;
}