.sidebar {
    background-color: #FFFFFF;
    border-right: 1px solid #D9D9D9;
    display: flex;
    flex-direction: column;
}

.sidebar-logo-container {
    display: flex;
    justify-content: center;
    padding: 4rem .5rem;
}

.sidebar-buttons-menu {
    border-radius: 5px;
    color: black;
    display: flex;
    text-decoration: none;
}

.sidebar-buttons-menu:hover {
    background-color: #E1F3FF;
}

.sidebar-buttons-menu + .sidebar-buttons-menu {
    margin-top: 0.5rem;
}

.sidebar-button-is-active {
    background-color: #E1F3FF;
    font-weight: bold;
}

.sidebar-buttons-menu span.svg {
    align-items: center;
    display: flex;
    height: 50px;
    justify-content: center;
    width: 50px;
}

.sidebar-buttons-menu span.svg svg {
    height: 30px;
    width: 30px;
}

.sidebar-buttons-menu span.text {
    align-items: center;
    display: flex;
}