div.containerResult {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
}

div.containerResult .board {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5rem;
    justify-content: center;
    margin-top: 5rem;
}

@media screen and (width >= 576px) {
    div.containerResult .board {
        justify-content: flex-start;
    }
}

div.containerResult .board .time {
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;
}

@media screen and (width >= 576px) {
    div.containerResult .board .time {
        flex: 0 1 auto;
    }
}

div.containerResult .board .time .result {
    align-items: center;
    display: flex;
    flex-direction: column;
}

div.containerResult .board .time .result span {
    font-size: 1rem;
    font-weight: 500;
}

div.containerResult .board .time .timer,
div.containerResult .board .time .start,
div.containerResult .board .time .finish {
    display: flex;
    font-size: .8rem;
    justify-content: space-between;
    gap: 2rem;
}

div.containerResult .board .time .timer span,
div.containerResult .board .time .start span,
div.containerResult .board .time .finish span {
    display: flex;
}

div.containerResult .board .amount {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

div.containerResult .board .amount .count {
    align-items: flex-start;
    border: 3px solid #E5E5E5;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-around;
    padding: 1rem 1.5rem;
}

div.containerResult .board .amount .count .pipe {
    background-color: #E5E5E5;
    height: 35px;
    width: 2px;
}

div.containerResult .board .amount .count .correct,
div.containerResult .board .amount .count .incorrect,
div.containerResult .board .amount .count .blank {
    align-items: center;
    display: flex;
    flex-direction: column;
}

div.containerResult .board .amount .count .correct b,
div.containerResult .board .amount .count .incorrect b,
div.containerResult .board .amount .count .blank b {
    font-size: 1.6rem;
    font-weight: 700;
}

div.containerResult .board .amount .count .blank b {
    color: #B1B1B1;
}

div.containerResult .board .amount .count .correct span,
div.containerResult .board .amount .count .incorrect span,
div.containerResult .board .amount .count .blank span {
    font-size: .9rem;
    text-wrap: nowrap;
}

div.containerResult .board .statistic {
    border: 3px solid #E5E5E5;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem 2rem;
    width: 100%;
}

@media screen and (width >= 992px) {
    div.containerResult .board .statistic {
        max-width: 400px;
    }
}

div.containerResult .board .statistic .title {
    font-size: 1.2rem;
    font-weight: bold;
}

div.containerResult .board .statistic .content {
    
}

div.containerResult .board .statistic .content .description {
    font-size: 1rem;
}

div.containerResult .board .statistic .content .bar {
    align-items: center;
    display: flex;
    flex-direction: row;
}

div.containerResult .board .statistic .content .bar span {
    display: flex;
    flex: 1 0 60px;
    font-size: 0.9rem;
    font-weight: bold;
    justify-content: center;
}