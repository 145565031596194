.payments-container {
    display: flex;
    flex: 1 1 100%;
}

.payments-container+.payments-container {
    margin-top: 1.5rem;
}

.payments-completed {
    align-items: flex-start;
    background-color: #FCFCFD;
    border: 1px solid #E9ECEF;
    border-radius: .5rem;
    display: grid;
    gap: 1rem;
    grid-template-columns: max-content 1fr 1fr max-content;
    grid-template-rows: 1fr;
    grid-template-areas: "icon plan status value";
    max-width: 700px;
    padding: 1rem;
    width: 100%;
}

@media screen and (width <=992px) {
    .payments-completed {
        gap: 1rem;
        grid-template-columns: 50px 1fr max-content;
        grid-template-rows: 1fr 1fr;
        grid-template-areas: "icon plan value" "icon status value";
    }
}

@media screen and (width <=768px) {
    .payments-completed {
        
    }
}

.payments-completed-items {
    display: flex;
    flex-direction: column;
}

.payments-completed-items:nth-child(1) {
    grid-area: icon;
}

.payments-completed-items:nth-child(2) {
    grid-area: plan;
}

.payments-completed-items:nth-child(3) {
    grid-area: status;
}

.payments-completed-items:nth-child(4) {
    grid-area: value;
}

.payments-completed-items h5,
.payments-completed-items p {
    margin: 0;
}

.payments-completed-items-svg {
    align-items: center;
    display: flex;
    justify-content: flex-start;
}

.payments-completed-items-svg svg {
    fill: #D1D1D1;
    height: 40px;
    width: 40px;
}

.payments-completed-items-svg-paid svg {
    fill: #41B26E;
}

.payments-completed-items-plan h5 {
    margin-bottom: .5rem;
}

.payments-completed-items-plan p {
    font-size: 0.9rem;
    line-height: 1.5rem;
}

.payments-completed-items-status {
    flex-direction: row;
}

.payments-completed-items-status h5 {
    margin-bottom: .5rem;
}

.payments-completed-items-status p {
    font-size: 0.9rem;
    line-height: 1.5rem;
}

.payments-completed-items-value {
    align-items: center;
    display: flex;
    gap: .5rem;
    justify-content: flex-end;
}

.payments-completed-items-value span {
    font-size: 1.3rem;
}

.payments-completed-items-value b {
    font-size: 2.2rem;
}