div.questions-incorrect {
    display: flex;
    flex-direction: column;
}

div.questions-incorrect div.legend-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    padding: 1rem 0 0 0;
}

div.questions-incorrect div.legend-container div.legend {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: .5rem;
}


div.questions-incorrect div.legend-container i.selected {
    background-color: #FFDADA;
    border: 1px solid #FFB0B0;
    border-radius: 3px;
    display: flex;
    height: 15px;
    width: 20px;
}

div.questions-incorrect div.legend-container i.correct {
    background-color: #E7FFF0;
    border: 1px solid #B6E9CA;
    border-radius: 3px;
    display: flex;
    height: 15px;
    width: 20px;
}

div.questions-incorrect div.legend-container span {
    display: flex;
    font-size: .8rem;
}

div.questions-incorrect div.question {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding: 1rem .5rem 2rem .5rem;
}

@media screen and (width >= 768px) {
    div.questions-incorrect div.question {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

div.questions-incorrect div.question + div.question {
    border-top: 1px solid #E5E5E5;
    padding-top: 2rem;
}

div.questions-incorrect div.title {
    align-items: center;
    display: flex;
    font-size: 1.1rem;
    font-weight: 600;
    gap: 2rem;
    justify-content: space-between;
    user-select: none;
}

div.questions-incorrect div.title div.image {
    align-items: center;
    display: flex;
    flex: 1 0 75px;
    justify-content: flex-start;
}

div.questions-incorrect div.title div.image img {
    border: none;
    height: auto;
    width: 75px;
}

div.questions-incorrect div.option {
    display: flex;
}

div.questions-incorrect div.alternative,
div.questions-incorrect div.alternative-correct,
div.questions-incorrect div.alternative-incorrect {
    align-items: center;
    display: flex;
    gap: 1rem;
    padding: .5rem;
    user-select: none;
}

@media screen and (width >= 768px) {
    div.questions-incorrect div.alternative,
    div.questions-incorrect div.alternative-correct,
    div.questions-incorrect div.alternative-incorrect {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

div.questions-incorrect div.alternative-correct {
    background-color: #E7FFF0;
    border: 1px solid #B6E9CA;
    border-radius: 5px;
}

div.questions-incorrect div.alternative-correct svg {
    fill: #41B26E;
    flex: 0 0 30px;
    height: auto;
    width: 30px;
}

div.questions-incorrect div.alternative-incorrect {
    background-color: #FFDADA;
    border: 1px solid #FFB0B0;
    border-radius: 5px;
}

div.questions-incorrect div.alternative i,
div.questions-incorrect div.alternative-correct i,
div.questions-incorrect div.alternative-incorrect i {
    align-items: center;
    background-color: #FFFFFF;
    border: 1px solid #C7C7C7;
    border-radius: 50%;
    color: #000000;
    display: flex;
    flex: 0 0 40px;
    font-style: normal;
    font-weight: 500;
    height: 40px;
    justify-content: center;
    width: 40px;
}

div.questions-incorrect div.alternative-incorrect svg {
    fill: #FF3232;
    flex: 0 0 20px;
    height: 20px;
    width: 20px;
}