.plan {
    transition: all .5s;
    user-select: none;
}

.card-header {
    align-items: center;
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    text-wrap: nowrap;
    white-space: nowrap;
}

.card-header h3 {
    position: relative;
}

.card-header h3 span {
    background-color: #FF891D;
    border-radius: .4rem;
    bottom: -1.5rem;
    color: #FFFFFF;
    font-size: 0.9rem;
    font-weight: 600;
    padding: .2rem .5rem;
    position: absolute;
    right: -1rem;
}

.card-value {
    align-items: flex-end;
    display: flex;
    justify-content: center;
    margin: 1rem 0;
}

.card-value .item {
    color: #1B5CD7;
    font-size: 1rem;
    font-weight: 600;
}

.card-value .item:nth-child(n+1):nth-child(-n+1) {
    font-size: 1.3rem;
    height: 3.4rem;
    line-height: 3.4rem;
    padding-right: .5rem;
}

.card-value .item:nth-child(n+2):nth-child(-n+2) {
    font-size: 4rem;
    height: 3.4rem;
    line-height: 3.4rem;
}

.card-value .item:nth-child(n+3):nth-child(-n+3) {
    font-size: 2rem;
    height: 2rem;
    line-height: 2rem;
}

.card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-body span {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: .5rem;
    justify-content: center;
}

.card-body b:first-child {
    background-color: #67E8B3;
    border-radius: 4px;
    font-size: 1.6rem;
    padding: .2rem 0.9rem;
}

.card-body b:last-child {
    font-size: 1.3rem;
}

.card-body div.by {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.card-body div.by div.by-text {
    font-size: 1rem;
    font-weight: 600;
}

.card-body ul {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
    gap: .75rem;
    list-style-type: none;
    justify-content: flex-start;
    padding: 0;
    margin: 1rem 0 2rem 0;
}

.card-body ul li {
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 1.3rem 1.3rem;
    line-height: .9rem;
    padding-left: 1.8rem;
}

.card-body ul li.checked {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2LjMxODYgMy40NDA4MUMxNi43MjY3IDMuODQ4ODggMTYuNzI2NyA0LjUxMTU3IDE2LjMxODYgNC45MTk2NEw3Ljk2MTQ5IDEzLjI3NjhDNy41NTM0MyAxMy42ODQ4IDYuODkwNzMgMTMuNjg0OCA2LjQ4MjY3IDEzLjI3NjhMMi4zMDQwOSA5LjA5ODIxQzEuODk2MDMgOC42OTAxNCAxLjg5NjAzIDguMDI3NDUgMi4zMDQwOSA3LjYxOTM5QzIuNzEyMTYgNy4yMTEzMiAzLjM3NDg1IDcuMjExMzIgMy43ODI5MiA3LjYxOTM5TDcuMjIzNzEgMTEuMDU2OUwxNC44NDMxIDMuNDQwODFDMTUuMjUxMSAzLjAzMjc1IDE1LjkxMzggMy4wMzI3NSAxNi4zMjE5IDMuNDQwODFIMTYuMzE4NloiIGZpbGw9IiMwNENCMDAiLz4KPC9zdmc+Cg==)
}

.card-body ul li.unchecked {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwLjU4ODkgOC45MzA1N0wxMy41NTM0IDUuOTYyOTdDMTMuNzQ1OSA1Ljc0NDIxIDEzLjg0NzggNS40NjAzNyAxMy44Mzg2IDUuMTY5MTRDMTMuODI5MyA0Ljg3NzkgMTMuNzA5NCA0LjYwMTE0IDEzLjUwMzQgNC4zOTUxQzEzLjI5NzMgNC4xODkwNyAxMy4wMjA2IDQuMDY5MjIgMTIuNzI5MyA0LjA1OTkyQzEyLjQzODEgNC4wNTA2MiAxMi4xNTQzIDQuMTUyNTcgMTEuOTM1NSA0LjM0NTA1TDguOTY3OSA3LjMwOTU5TDUuOTk0OTUgNC4zMzU4N0M1Ljg4ODQ2IDQuMjI5MzkgNS43NjIwNSA0LjE0NDkyIDUuNjIyOTIgNC4wODcyOUM1LjQ4Mzc5IDQuMDI5NjYgNS4zMzQ2NyA0IDUuMTg0MDggNEM1LjAzMzQ4IDQgNC44ODQzNiA0LjAyOTY2IDQuNzQ1MjMgNC4wODcyOUM0LjYwNjEgNC4xNDQ5MiA0LjQ3OTY5IDQuMjI5MzkgNC4zNzMyIDQuMzM1ODdDNC4yNjY3MiA0LjQ0MjM2IDQuMTgyMjUgNC41Njg3OCA0LjEyNDYyIDQuNzA3OTFDNC4wNjY5OSA0Ljg0NzA0IDQuMDM3MzMgNC45OTYxNiA0LjAzNzMzIDUuMTQ2NzVDNC4wMzczMyA1LjI5NzM0IDQuMDY2OTkgNS40NDY0NiA0LjEyNDYyIDUuNTg1NTlDNC4xODIyNSA1LjcyNDcyIDQuMjY2NzIgNS44NTExNCA0LjM3MzIgNS45NTc2Mkw3LjM0NjkxIDguOTMwNTdMNC4zODIzNyAxMS44OTc0QzQuMjY2MTEgMTIuMDAxMyA0LjE3MjI3IDEyLjEyNzkgNC4xMDY2IDEyLjI2OTNDNC4wNDA5MyAxMi40MTA4IDQuMDA0ODEgMTIuNTY0MSA0LjAwMDQ1IDEyLjcyQzMuOTk2MDkgMTIuODc1OSA0LjAyMzU3IDEzLjAzMSA0LjA4MTIzIDEzLjE3NTlDNC4xMzg4OSAxMy4zMjA4IDQuMjI1NSAxMy40NTI0IDQuMzM1NzcgMTMuNTYyN0M0LjQ0NjA0IDEzLjY3MyA0LjU3NzY0IDEzLjc1OTYgNC43MjI1NCAxMy44MTcyQzQuODY3NDMgMTMuODc0OSA1LjAyMjU3IDEzLjkwMjQgNS4xNzg0NSAxMy44OThDNS4zMzQzNCAxMy44OTM3IDUuNDg3NjkgMTMuODU3NSA1LjYyOTEzIDEzLjc5MTlDNS43NzA1OCAxMy43MjYyIDUuODk3MTMgMTMuNjMyNCA2LjAwMTA2IDEzLjUxNjFMOC45Njc5IDEwLjU1MTZMMTEuOTMxNyAxMy41MTYxQzEyLjE0NjcgMTMuNzMxMiAxMi40Mzg0IDEzLjg1MiAxMi43NDI1IDEzLjg1MkMxMy4wNDY3IDEzLjg1MiAxMy4zMzg0IDEzLjczMTIgMTMuNTUzNCAxMy41MTYxQzEzLjc2ODUgMTMuMzAxIDEzLjg4OTMgMTMuMDA5NCAxMy44ODkzIDEyLjcwNTJDMTMuODg5MyAxMi40MDExIDEzLjc2ODUgMTIuMTA5NCAxMy41NTM0IDExLjg5NDNMMTAuNTg4OSA4LjkzMDU3WiIgZmlsbD0iI0ZGMDAwMCIvPgo8L3N2Zz4K)
}

.card-body button {
    font-weight: 500;
    width: 100%;
}

.plan:last-of-type .card-body button {
    background-color: #0D6EFD;
    color: #FFFFFF;
}

.plan:last-of-type .card-body button:hover {
    background-color: #0B5ED7;
}