div.container-list-simulated-skeleton {
    align-items: center;
    border: 1px solid #D9D9D9;
    border-radius: .5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 3rem;
    justify-content: space-between;
    margin: 3rem 0;
    max-width: 1000px;
    padding: 1rem 1.5rem;
    position: relative;
}

div.container-list-simulated-skeleton div.block {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

div.container-list-simulated-skeleton div.icon svg {
    fill: #B8B8B8;
    height: 50px;
    width: 50px;
}

div.container-list-simulated-skeleton div.text {
    background-color: #EEEEEE;
    border-radius: .3rem;
    color: #EEEEEE;
}

div.container-list-simulated-skeleton div.pizza {
    border: 15px solid #EEEEEE;
    border-radius: 50%;
    color: #EEEEEE;
    height: 80px;
    width: 80px;
}

div.container-list-simulated-skeleton div.button {
    background-color: #EEEEEE;
    border-radius: .3rem;
    color: #EEEEEE;
    padding: .5rem 1rem;
}