.container-simulated {
    align-items: center;
    border: 1px solid #D9D9D9;
    border-radius: .5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 3rem;
    justify-content: space-between;
    margin: 2rem 0;
    max-width: 1000px;
    padding: 1rem 1.5rem;
    animation: teste 1s ease-in;
}

@keyframes teste {
    from {
        opacity: 0;
    }
  
    to {
        opacity: 1;
    }
}

@media screen and (width <= 768px) {
    .container-simulated {
        justify-content: stretch;
    }
}

.container-simulated-items {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

@media screen and (width <= 768px) {
    .container-simulated-items {
        flex: 1 1 auto;
    }
}

.container-simulated-items:first-of-type {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.container-simulated-items:first-of-type svg {
    fill: #B8B8B8;
    height: 50px;
    width: 50px;
}

.container-simulated-items p {
    margin: 0;
}