div.loading-logo {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
}

div.dashboard-container {
    display: grid;
    grid-area: main;
    grid-template-columns: 1fr;
    grid-template-rows: 100px 1fr;
    grid-template-areas: "header"
                        "main";
    margin-left: 0;
    overflow: auto;
    transition: margin-left 0.5s ease-in-out;
}

@media screen and (width >= 768px) {
    div.dashboard-container {
        grid-template-columns: 250px 1fr;
        grid-template-rows: 100px 1fr;
        grid-template-areas: "sidebar header"
                            "sidebar main";
        min-height: 100vh;
        margin-left: -250px;
    }
}

div.dashboard-sidebar-toggle {
    margin-left: 0;
    transition: margin-left 0.5s ease-in-out;
}

div.header {
    background-color: rgb(255, 255, 255);
    grid-area: header;
}

div.sidebar {
    grid-area: sidebar;
    transition: all 500ms;
}