

div.container-redefine-pass {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 0 auto;
    max-width: 1000px;
    padding: 20px;
}

@media screen and (width >= 768px) {
    div.container-redefine-pass {
        flex-direction: row;
        padding: 100px 20px;
    }
}

div.container-redefine-pass div.left {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
}

div.container-redefine-pass div.right {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

div.container-redefine-pass div.right div.redefine-pass-logo-container {
    display: flex;
    justify-content: center;
    padding: 5rem 3rem;
}

div.container-redefine-pass div.right form {
    max-width: 400px;
    width: 100%;
}

div.container-redefine-pass div.right h2,
div.container-redefine-pass div.right p {
    text-align: center;
}

div.container-redefine-pass button.button {
    background-color: #0247BC;
    font-weight: bold;
    margin: 1rem 0 0 0;
    width: 100%;
}

div.container-redefine-pass img.email-marketing {
    border: none;
    height: auto;
    max-width: 200px;
    width: 100%;
}

@media screen and (width >= 768px) {
    div.container-redefine-pass img.email-marketing {
        max-width: 350px;
    }
}