div.qrcode-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

div.qrcode-container div.listGroup {
    display: flex;
    flex-direction: column;
    width: 100%;
}

div.qrcode-container div.listRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: .5rem 0;
}

div.qrcode-container div.listRow + div.listRow {
    border-top: 1px solid #DEE2E6;
}

div.qrcode-container div.listCol {
    display: flex;
    flex-direction: column;
}

div.qrcode-container div.listCol:first-of-type {
    align-items: flex-start;
}

div.qrcode-container div.listCol:last-of-type {
    align-items: flex-end;
}

div.qrcode-container div.listCol div.stopWatch {
    color: #FF0000;
}

div.qrcode-container div.listCol div.expire {
    color: #000000;
    font-size: .8rem;
}

div.qrcode-container img {
    height: auto;
    width: 200px;
}

div.qrcode-container div.button-container {
    display: flex;
}

div.instructions {
    display: flex;
    flex-direction: column;
    font-size: .7rem;
    margin-top: 1rem;
}

div.instructions-title {
    border-bottom: 1px solid #DEE2E6;
    padding: .5rem 0;
}

div.instructions-title span {
    color: #000000;
}

div.instructions-content {
    color: #727272;
    display: flex;
    flex-direction: column;
    gap: .4rem;
    margin-top: .5rem;
}

div.instructions-row {
    display: flex;
    flex-direction: row;
    gap: .5rem;
}

div.instructions-number {
    align-items: center;
    background-color: #D2D2D2;
    border-radius: 50%;
    color: #FFFFFF;
    display: flex;
    font-size: .6rem;
    height: 1rem;
    justify-content: center;
    width: 1rem;
}

div.instructions-text {
    color: #727272;
}