div.container-list-payments-skeleton {
    align-items: center;
    border: 1px solid #D9D9D9;
    border-radius: .5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
    margin: 3rem 0;
    max-width: 700px;
    padding: 1rem 1.5rem;
    position: relative;
}

div.container-list-payments-skeleton div.block {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

div.container-list-payments-skeleton div.icon svg {
    fill: #B8B8B8;
    height: 50px;
    width: 50px;
}

div.container-list-payments-skeleton div.text {
    background-color: #EEEEEE;
    border-radius: .3rem;
    color: transparent;
}

div.container-list-payments-skeleton div.value b {
    background-color: #EEEEEE;
    border-radius: .3rem;
    color: transparent;
    font-size: 1.2rem;
}

div.container-list-payments-skeleton div.button {
    background-color: #EEEEEE;
    border-radius: .3rem;
    color: transparent;
    padding: .5rem 1rem;
}