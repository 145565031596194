.container-user-skeleton {
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

@media screen and (width >= 768px) {
    .container-user-skeleton {
        padding: 1.5rem;
    }
}

.container-user-skeleton .text {
    background-color: #EEEEEE;
    border-radius: .375rem;
    color: #EEEEEE;
}

.container-user-skeleton .label {
    display: flex;
}

.container-user-skeleton .input {
    background-color: #EEEEEE;
    border-radius: .375rem;
    color: #EEEEEE;
    display: flex;
    flex: 1;
    padding: .4rem 0;
}

.container-user-skeleton .button {
    background-color: #EEEEEE;
    border-radius: .375rem;
    color: #EEEEEE;
    display: flex;
    flex: 1;
    margin: 1rem 0 0 0;
    padding: .5rem 0;
}

.container-user-skeleton .container-user,
.container-user-skeleton .container-email,
.container-user-skeleton .container-conta  {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    margin: 3rem 0 0 0;
    max-width: 400px;
    position: relative;
}