@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    margin: 0;
    padding: 0;
}

body {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

h2 {
    font-weight: 700;
}

.buttonDefault {
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.buttonDefault span {
    align-items: center;
    display: flex;
}

div.shimmer-effect {
    animation: shimmer 1s infinite linear;
    background: linear-gradient(-45deg, transparent 40%, #FFFFFF 50%, transparent 60%);
    background-size: 300%;
    background-position-x: 100%;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

@keyframes shimmer {
    to {
        background-position-x: 0%
    }
}