div.paid-modal-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1.5rem; 
}  

div.paid-modal-content div.listGroup {
    display: flex;
    flex-direction: column;
    width: 100%;
}

div.paid-modal-content div.listRow {
    border-bottom: 1px solid #DEE2E6;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: .5rem 0;
}

div.paid-modal-content div.listCol {
    display: flex;
    flex-direction: column;
}

div.paid-modal-content div.listCol:first-of-type {
    align-items: flex-start;
}

div.paid-modal-content div.listCol:last-of-type {
    align-items: flex-end;
}

div.paid-modal-content div.icon {
    padding: 1rem;
}

div.paid-modal-content div.icon svg {
    fill: #41B26E;
    height: 80px;
    width: 80px;
}

div.paid-modal-content div.title {
    font-size: 1.4rem;
    font-weight: 600;
}

div.paid-modal-content div.text {
    color: #677183;
    font-size: .9rem;
    text-align: center;
}