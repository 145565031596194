.header {
    background-color: #FFFFFF;
}
.button-header-menu {
    align-items: center;
    display: flex;
    height: 25px;
    justify-content: flex-start;
    width: 25px;
}
.button-header-menu svg {
    fill: black;
    height: 25px;
    width: 25px;
}
#dropdown-button-header-user {
    align-items: center;
    background-color: #0247BC;
    border: none;
    border-radius: 50%;
    display: flex;
    height: 50px;
    justify-content: center;
    width: 50px;
}
#dropdown-button-header-user::after {
    display: none !important;
}

#dropdown-button-header-user svg {
    fill: #FFFFFF;
    height: 25px;
    width: 25px;
}

.dropdown-header-user-menu {
    min-width: 280px;
}

.dropdown-option-header-user {
    font-size: 1rem;
    font-weight: bold;
    padding: .5rem 1.5rem 0;
}

.dropdown-option-header-mail {
    font-size: 0.8rem;
    padding: 0 1.5rem .5rem;
}

.dropdown-option-header-items {
    align-items: center;
    color: #000000;
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
    padding: .5rem 1.5rem;
}

.dropdown-option-header-items:hover {
    background-color: #EDEDED;
    color: #000000;
}

.dropdown-option-header-items:active {
    background-color: #EDEDED;
    color: #000000;
}

.dropdown-option-header-items svg {
    fill: #000000;
    height: 25px;
    width: 25px;
}

.dropdown-option-header-items-text {
    font-size: 1rem;
}